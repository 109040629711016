import { useState, useEffect } from "react"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteUserList,
  getMyAccountListsWithLineItems,
  deleteProductFromList,
  deleteMultiProductFromList,
} from "@/store/features/favoritesSlice"
import { getTranslations } from "@/components/ListConfirmationModal/localization"
import Image from "@/components/Shared/Image"
import CloseIcon from "@/public/icons/modal-close.svg"
import Styles from "@/components/ListConfirmationModal/index.module.scss"
import { selectGenericState } from "@/store/features/genericSlice"
import { MY_LISTS_PAGE_PATH, PRODUCT_BEST_SELLERS } from "@/constants/routes"
const ListConfirmationModal = props => {
  const { t } = useTranslation("common")
  const staticText = getTranslations(t)
  const dispatch = useDispatch()
  const router = useRouter()
  const {
    list = {},
    openConforMationModal = false,
    noLineItems = false,
    deleteList = false,
    deletefromListDetails = false,
    deleteFromList = false,
    closeConformationModal = () => {},
    deleteConfirmationModal = () => {},
    setSelectedList = () => {},
    handleSuccessCallBack = () => {},
    closeModalWithOutDelete = () => {},
    selectedItems = [],
    multiDeleteFromList = false,
  } = props || {}
  const {
    globalConfig: {
      internationalization: { locale: newLocale = "", language = "en" } = {},
    } = {},
  } = useSelector(selectGenericState)
  const [headerTxt, setHeaderTxt] = useState("")
  const [decTxt, setDecTxt] = useState("")
  const [btnTxt, setBtnTxt] = useState("")
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [deleteSuccessResponse, setDeleteSuccessResponse] = useState({})
  const [actionBtnDisabled, setActionBtnDisabled] = useState(false)
  const handleClose = () => {
    if (deleteSuccess) {
      if (multiDeleteFromList) {
        handleSuccessCallBack(deleteSuccessResponse)
      } else {
        dispatch(getMyAccountListsWithLineItems())
        handleSuccessCallBack()
      }
    }
    if (btnTxt !== "My Lists" && deletefromListDetails) {
      closeModalWithOutDelete()
    } else if (multiDeleteFromList && !deleteSuccess) {
      closeModalWithOutDelete()
    } else {
      closeConformationModal()
    }
    deleteConfirmationModal()
  }

  const handleActionbtn = () => {
    if (btnTxt === "My Lists") {
      if (deleteList) {
        router.push(`/${language}${MY_LISTS_PAGE_PATH}`)
      } else if (deleteFromList) {
        setSelectedList(deleteSuccessResponse?.id)
        deleteConfirmationModal()
        router.push(
          `/${language}${MY_LISTS_PAGE_PATH}?listId=${deleteSuccessResponse?.id}`
        )
      }
    } else if (btnTxt === "Close" && deleteFromList) {
      deleteConfirmationModal()
      closeConformationModal()
    }
    if (multiDeleteFromList && deleteSuccess) {
      handleSuccessCallBack(deleteSuccessResponse)
    } else if (deleteSuccess) {
      if (!deletefromListDetails) {
        dispatch(getMyAccountListsWithLineItems())
      }
      handleSuccessCallBack()
      closeConformationModal()
    } else if (noLineItems) {
      router.push(`/${language}${PRODUCT_BEST_SELLERS}`)
    } else if (deleteList || deletefromListDetails) {
      setActionBtnDisabled(true)
      dispatch(deleteUserList(list))
        .unwrap()
        .then(resonse => {
          setDeleteSuccess(true)
          setActionBtnDisabled(false)
          setHeaderTxt(staticText?.listActionSuccess)
          setDecTxt(staticText?.listActionDeleteMsg)
          setBtnTxt(staticText?.listActionDeleteSuccessBtn)
        })
        .catch(error => {
          console.log(error)
        })
    } else if (deleteFromList) {
      setActionBtnDisabled(true)
      dispatch(deleteProductFromList(list))
        .unwrap()
        .then(resonse => {
          setDeleteSuccessResponse(resonse?.data)
          setDeleteSuccess(true)
          setActionBtnDisabled(false)
          setHeaderTxt(staticText?.listActionSuccess)
          setDecTxt(staticText?.deleteFromListConfirmMsg)
          setBtnTxt(staticText?.closeBtnTextForList)
          dispatch(getMyAccountListsWithLineItems())
        })
        .catch(error => {
          console.log(error)
        })
    } else if (multiDeleteFromList) {
      setActionBtnDisabled(true)
      dispatch(deleteMultiProductFromList({ list, selectedItems }))
        .unwrap()
        .then(resonse => {
          setDeleteSuccessResponse(resonse?.data)
          setDeleteSuccess(true)
          setActionBtnDisabled(false)
          setHeaderTxt(staticText?.listActionSuccess)
          setDecTxt(staticText?.multiDeleteSuccessDec)
          setBtnTxt(staticText?.closeBtnTextForList)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  useEffect(() => {
    if (noLineItems) {
      setHeaderTxt(staticText?.noListTitle)
      setDecTxt(staticText?.noListMsg)
      setBtnTxt(staticText?.bestSellersBtn)
    } else if (deleteList || deletefromListDetails) {
      setHeaderTxt(staticText?.deleteListTitle)
      setDecTxt(staticText?.deleteDec)
      setBtnTxt(staticText?.deleteListBtn)
    } else if (deleteFromList) {
      const locale = newLocale === "en-CA" ? "en-US" : newLocale
      const ListName = list?.listName[locale]
      setHeaderTxt(staticText?.deleteFromList)
      setDecTxt(`${staticText?.deleteProductFormListCloseMsg} '${ListName}'.`)
      setBtnTxt(staticText?.deleteProductFromList)
    } else if (multiDeleteFromList) {
      setHeaderTxt(staticText?.multiDeleteHeader)
      setDecTxt(staticText?.multiDeleteFromListDec)
      setBtnTxt(staticText?.multiDeleteBtnTex)
    }
  }, [
    noLineItems,
    deleteList,
    deleteFromList,
    deletefromListDetails,
    multiDeleteFromList,
  ])

  useEffect(() => {
    document.body.style.overflow = openConforMationModal ? "hidden" : "auto"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [openConforMationModal])

  return openConforMationModal ? (
    <div className={Styles.ListConfirmationModalWrapper}>
      <div
        className={`list-confirmation-modal ${
          deleteFromList ? "deleteFromList" : ""
        } `}
      >
        <div className="close-btn" onClick={handleClose}>
          <Image
            src={CloseIcon?.src}
            role="presentation"
            alt="close"
            width="17"
            height="30"
          />
        </div>
        <div className="list-ocnfirmation-content">
          <div className="header">{headerTxt}</div>
          <div className="discription">{decTxt}</div>
          <button
            className="cta-button"
            onClick={handleActionbtn}
            disabled={actionBtnDisabled}
          >
            <div className="cta-btn-txt">{btnTxt}</div>
          </button>
        </div>
      </div>
    </div>
  ) : null
}

export default ListConfirmationModal
