import React, { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useRouter } from "next/router"
import dynamic from "next/dynamic"
import { getTranslations } from "@/components/SaveToList/localization"
import Image from "@/components/Shared/Image"
import { SAVE_TO_LIST_NAME_FIELD_REGEX } from "@/constants/regex"
import {
  addProductToList,
  editList,
  getMyAccountLists,
  getMyAccountListsWithLineItems,
  moveItemsFromList,
} from "@/store/features/favoritesSlice"
import { selectGenericState } from "@/store/features/genericSlice"
import {
  getIsNameDuplicate,
  getUserCreatedLists,
  getIsSystemDefinedName,
} from "@/utils/helper"
import { MY_LISTS_PAGE_PATH } from "@/constants/routes"
import CloseIcon from "@/public/icons/modal-close.svg"
import Styles from "@/components/SaveToList/index.module.scss"
import {
  dataLayerCreateNewSaveListButton,
  dataLayerMyListSaveListButton,
  dataLayerCreateNewListButton,
  closeSaveToListAnalytics,
  closeSelectMyListAnalytics,
  closeCreateNewListAnalytics,
  closeCreateNewListSuccessAnalytics,
  closeSelectMyListSuccessAnalytics,
} from "@/components/SaveToList/analytics"

const ListForm = dynamic(() => import("@/components/SaveToList/ListForm"))
const CreateForm = dynamic(() => import("@/components/SaveToList/CreateForm"))

const SaveToList = props => {
  const { t } = useTranslation("common")
  const staticText = getTranslations(t)
  const {
    globalConfig: {
      internationalization: { locale: newLocale = "", language = "" } = {},
    } = {},
  } = useSelector(selectGenericState)
  const {
    productSku = "",
    showListSaveAndCreate = false,
    createList = false,
    listSaveAndCreateClose = () => {},
    handleSuccessCallBack = () => {},
    listData = {},
    editListText = "",
    selectedListDetails = {},
    getProductInfo = () => {},
    moveToList = false,
    moveOrDeleteFromListName = "",
    currentList = {},
    productData = [],
    selectedItems = [],
    productQtyObj = {},
  } = props || {}

  const dispatch = useDispatch()
  const router = useRouter()
  const [saveBtnStatus, setSaveBtnStatus] = useState(true)
  const [createNewList, setCreateNewList] = useState(createList)
  const [headerText, setHeaderText] = useState("")
  const [decText, setDecText] = useState("")
  const [userLists, setUserLists] = useState([])
  const [listNameValidationList, setListNameValidationList] = useState([])
  const [nameValidationErrorMsg, setNameValidationErrorMsg] = useState("")
  const [showNameValidationError, setShowNameValidationError] = useState(false)
  const [newListName, setNewListName] = useState("")
  const [projectName, setProjectName] = useState("")
  const [dec, setDec] = useState("")
  const [selectedList, setSelectedList] = useState("")
  const [createListData, setCreateListData] = useState({})
  const [selectedListData, setSelectedListData] = useState({})
  const [createListAddItemsSuccess, setCreateListAddItemsSuccess] =
    useState(false)
  const [count, setCount] = useState(0)
  const [listNamesLoader, setListNamesLoader] = useState(true)
  const productJsonData = getProductInfo("", false, false)
  const productInfo = JSON.parse(productJsonData ?? "{}")
  const [moveToListResponse, setMoveToListResponse] = useState({})

  const getUserCreatedListData = () => {
    dispatch(getMyAccountLists())
      .unwrap()
      .then(response => {
        const locale = newLocale === "en-CA" ? "en-US" : newLocale
        const data = getUserCreatedLists(
          response,
          locale,
          moveOrDeleteFromListName
        )
        setUserLists(data?.lists)
        moveOrDeleteFromListName
          ? setListNameValidationList([
              ...data?.listNames,
              moveOrDeleteFromListName,
            ])
          : setListNameValidationList(data?.listNames)

        setListNamesLoader(false)
      })
      .catch(error => {})
  }

  const listNameValidation = e => {
    let value = e?.target?.value
    value = value?.length < 37 ? value : value.slice(0, 36)
    setNewListName(value)
    setNameValidationErrorMsg(staticText.createNameError)
    if (
      value?.trim().length < 3 ||
      value?.trim().length > 36 ||
      !SAVE_TO_LIST_NAME_FIELD_REGEX.test(value)
    ) {
      setNameValidationErrorMsg(staticText.createNameError)
      setShowNameValidationError(true)
    } else if (getIsNameDuplicate(value.trim(), listNameValidationList)) {
      setNameValidationErrorMsg(staticText.createSameNameError)
      setShowNameValidationError(true)
    } else if (getIsSystemDefinedName(value.trim())) {
      setNameValidationErrorMsg(staticText.createSystemNameError)
      setShowNameValidationError(true)
    } else {
      setShowNameValidationError(false)
    }
  }
  const projectNameValidation = e => {
    const value = e?.target?.value
    value?.length < 37
      ? setProjectName(value)
      : setProjectName(value.slice(0, 36))
  }

  const handleSelectedList = list => {
    setSelectedList(list?.id)
    setSelectedListData(list)
  }

  const closeModalHandle = () => {
    if (saveBtnStatus && !createNewList) {
      const pageType = "plp"
      closeSaveToListAnalytics(productInfo, pageType)
    }
    if (!saveBtnStatus && !createNewList) {
      const pageType = "plp"
      const selectedData = selectedListData.name
      closeSelectMyListAnalytics(productInfo, pageType, selectedData)
    }
    if (createListAddItemsSuccess && !saveBtnStatus && !createNewList) {
      const pageType = "plp"
      const selectedData = selectedListData.name
      const successTitle = staticText?.saveToListActionTitle
      closeSelectMyListSuccessAnalytics(
        productInfo,
        pageType,
        selectedData,
        successTitle
      )
    }
    if (saveBtnStatus && createNewList) {
      const pageType = "plp"
      const createNewListTitle = staticText?.saveToListCreateNewBtn
      closeCreateNewListAnalytics(productInfo, pageType, createNewListTitle)
    }
    if (createListAddItemsSuccess && !saveBtnStatus && createNewList) {
      const pageType = "plp"
      const createListData = newListName
      const createNewListTitle = staticText?.saveToListCreateNewBtn
      const successTitle = staticText?.saveToListActionTitle
      closeCreateNewListSuccessAnalytics(
        productInfo,
        pageType,
        createListData,
        createNewListTitle,
        successTitle
      )
    }
    if (moveToList && createListAddItemsSuccess)
      handleSuccessCallBack(moveToListResponse)
    listSaveAndCreateClose()
  }

  const handleCreateNewList = () => {
    const pageType = "plp"
    const createNewListTitle = staticText?.saveToListCreateNewBtn
    dataLayerCreateNewListButton(productInfo, pageType, createNewListTitle)
    setSelectedList("")
    setCreateNewList(true)
    setSaveBtnStatus(true)
  }

  const handleDec = e => {
    const { value } = e.target
    setCount(value?.length)
    setDec(value)
  }
  const handleMoveItemsFromList = async (list, listInfo) => {
    dispatch(
      moveItemsFromList({
        list: list,
        listData: listInfo,
        currentList,
        productData,
        selectedItems,
        productQtyObj,
      })
    )
      .unwrap()
      .then(response => {
        const locale = newLocale === "en-CA" ? "en-US" : newLocale
        setCreateListData({
          name: response?.addResponse?.name[locale],
          version: response?.addResponse?.version,
          id: response?.addResponse?.id,
        })
        setHeaderText(staticText?.saveToListActionTitle)
        setDecText(staticText?.moveToListSuccessDec)
        setCreateListAddItemsSuccess(true)
        setSaveBtnStatus(false)
        setMoveToListResponse(response)
      })
      .catch(error => {})
  }
  const handleCreateListAndAddProductsToIt = async () => {
    if (createListAddItemsSuccess) {
      if (moveToList) handleSuccessCallBack(moveToListResponse)
      listSaveAndCreateClose()
      return
    }
    setSaveBtnStatus(true)

    const listInfo = {}
    const list = {}

    if (createNewList && !editListText) {
      const pageType = "plp"
      const createListData = newListName
      const successDesc = staticText?.saveEventMsgCreateList
      const createNewListTitle = staticText?.saveToListCreateNewBtn
      const successTitle = staticText?.saveToListActionTitle
      listInfo["name"] = newListName?.trim()
      listInfo["project"] = projectName
      listInfo["description"] = dec
      dataLayerCreateNewSaveListButton(
        productInfo,
        pageType,
        createListData,
        successDesc,
        createNewListTitle,
        successTitle
      )
    } else {
      const pageType = "plp"
      const selectedData = selectedListData.name
      const successDesc = staticText?.saveEventMsgMyList
      const successTitle = staticText?.saveToListActionTitle
      list["id"] = selectedListData?.id ?? selectedListDetails[0]?.id
      list["version"] =
        selectedListData?.version ?? selectedListDetails[0]?.version
      listInfo["name"] = newListName?.trim()
      listInfo["project"] = projectName
      listInfo["description"] = dec
      dataLayerMyListSaveListButton(
        productInfo,
        pageType,
        selectedData,
        successDesc,
        successTitle
      )
    }
    if (moveToList) {
      handleMoveItemsFromList(list, listInfo)
      return
    }
    editListText
      ? dispatch(
          editList({
            list: list,
            listData: listInfo,
          })
        )
          .unwrap()
          .then(async response => {
            setSaveBtnStatus(false)
            handleSuccessCallBack(response)
            dispatch(getMyAccountListsWithLineItems())
          })
          .catch(errror => {})
      : dispatch(
          addProductToList({
            sku: productSku,
            list: list,
            listData: listInfo,
            createList,
          })
        )
          .unwrap()
          .then(async response => {
            const locale = newLocale === "en-CA" ? "en-US" : newLocale
            setCreateListData({
              name: response?.name[locale],
              version: response?.version,
              id: response?.id,
            })
            setHeaderText(staticText?.saveToListActionTitle)
            setDecText(staticText?.saveToListActionDec)
            setCreateListAddItemsSuccess(true)
            setSaveBtnStatus(false)
            handleSuccessCallBack()
            createList
              ? handleSuccessCallBack(response)
              : handleSuccessCallBack()
          })
          .catch(errror => {})
  }

  const handleListNameClick = () => {
    router
      .replace({
        pathname: `/${language}${MY_LISTS_PAGE_PATH}`,
        query: { listId: createListData?.id },
      })
      .then(() => {
        router.reload()
      })
  }

  useEffect(() => {
    getUserCreatedListData()
  }, [])

  useEffect(() => {
    if (moveToList) {
      setHeaderText(staticText?.moveToList)
      setDecText(staticText?.moveToList)
    }
  }, [moveToList])

  useEffect(() => {
    if (Object.keys(listData)?.length > 0) {
      setProjectName(listData?.project)
      setNewListName(listData?.name)
      setDec(listData?.description)
      setHeaderText(editListText)
      setDecText(staticText?.saveToListCreateDec)
      handleSelectedList(selectedList)
    }
  }, [listData])

  useEffect(() => {
    document.body.style.overflow = showListSaveAndCreate ? "hidden" : "auto"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [showListSaveAndCreate])

  useEffect(() => {
    if (
      (createNewList && !showNameValidationError) ||
      (!createNewList && selectedList)
    ) {
      setSaveBtnStatus(false)
    } else {
      setSaveBtnStatus(true)
    }
  }, [newListName, selectedList])

  useEffect(() => {
    if (!editListText) {
      if (createNewList) {
        setHeaderText(staticText?.saveToListCreateHeader)
        setDecText(staticText?.saveToListCreateDec)
      } else if (moveToList) {
        setHeaderText(staticText?.moveToList)
        setDecText(staticText?.moveToListDec)
      } else {
        setHeaderText(staticText?.saveToListSaveHeader)
        setDecText(staticText?.saveToListSaveDec)
      }
    }
    setSaveBtnStatus(true)
  }, [createNewList])

  useEffect(() => {
    let timerConst
    if (createListAddItemsSuccess) {
      timerConst = setTimeout(() => {
        if (moveToList) handleSuccessCallBack(moveToListResponse)
        listSaveAndCreateClose()
        setCreateListAddItemsSuccess(false)
        setNameValidationErrorMsg("")
        setNewListName("")
        setProjectName("")
        setDec("")
        setMoveToListResponse({})
      }, 3000)
    }
    return () => {
      clearTimeout(timerConst)
    }
  }, [createListAddItemsSuccess])

  if (listNamesLoader && showListSaveAndCreate) {
    return (
      <div className={Styles.SaveToListWrapper}>
        <div className="save-to-list-modal">
          <div className="loading-container">
            <div className="loading-text">{staticText?.saveToListLoading}</div>
          </div>
        </div>
      </div>
    )
  }

  return showListSaveAndCreate ? (
    <div className={Styles.SaveToListWrapper}>
      <div className="save-to-list-modal">
        <div className="modal-body">
          <div className="back-close-btn">
            <div className="close-btn" onClick={closeModalHandle}>
              <Image
                src={CloseIcon?.src}
                role="presentation"
                alt="close"
                width="30"
                height="30"
              />
            </div>
          </div>
          <div className="modal-content">
            <div className="list-header">{headerText}</div>
            <div className="list-dec">{decText}</div>
            {!createNewList && !createListAddItemsSuccess ? (
              <div className="create-new-list" onClick={handleCreateNewList}>
                {staticText?.saveToListCreateNewBtn}
              </div>
            ) : null}
            {createListAddItemsSuccess && !createList ? (
              <div className="create-new-list" onClick={handleListNameClick}>
                {createListData?.name}
              </div>
            ) : null}
            {!createListAddItemsSuccess && createNewList ? (
              <CreateForm
                listNameValidation={listNameValidation}
                newListName={newListName}
                showNameValidationError={showNameValidationError}
                nameValidationErrorMsg={nameValidationErrorMsg}
                projectNameValidation={projectNameValidation}
                projectName={projectName}
                dec={dec}
                handleDec={handleDec}
                count={count}
              />
            ) : null}
            {!createListAddItemsSuccess && !createNewList ? (
              <ListForm
                createNewList={createNewList}
                createListAddItemsSuccess={createListAddItemsSuccess}
                userLists={userLists}
                handleSelectedList={handleSelectedList}
                selectedList={selectedList}
              />
            ) : null}
          </div>
        </div>

        <div className="action-btn">
          <button
            className="action-btn-item"
            disabled={saveBtnStatus}
            onClick={handleCreateListAndAddProductsToIt}
          >
            <span className="action-btn-item-txt">
              {createListAddItemsSuccess
                ? createList
                  ? staticText?.saveToListNewActionSave
                  : staticText?.saveToListActionSaved
                : staticText?.saveToListActionSave}
            </span>
          </button>
        </div>
      </div>
    </div>
  ) : null
}
export default SaveToList
