export const getTranslations = t => {
  return {
    createNameError: t("kf.saveToList.create.name.error"),
    createSameNameError: t("kf.saveToList.create.same.name.error"),
    createSystemNameError:t("kf.saveToList.create.system.name.error"),
    saveToListActionTitle: t("kf.saveToList.action.success.title"),
    saveToListActionDec: t("kf.saveToList.action.success.dec"),
    saveToListCreateHeader: t("kf.saveToList.create.header"),
    saveToListCreateDec: t("kf.saveToList.create.dec"),
    saveToListSaveHeader: t("kf.saveToList.save.header"),
    saveToListSaveDec: t("kf.saveToList.save.dec"),
    saveToListLoading: t("kf.saveToList.loading"),
    saveToListCreateNewBtn: t("kf.saveToList.create.new.btn.txt"),
    saveToListActionSaved: t("kf.saveToList.action.saved"),
    saveToListActionSave: t("kf.saveToList.action.save"),
    saveToListNewActionSave: t("kf.saveToList.action.new.saved"),
    createLabel: t("kf.saveToList.create.name.label"),
    projectLabel: t("kf.saveToList.create.project.label"),
    dec: t("kf.saveToList.create.dec.label"),
    title: t("kf.saveToList.myList.titile"),
    saveEventMsgCreateList: t("kf.save.eventMsg.create.list"),
    saveEventMsgMyList: t("kf.save.eventMsg.myList"),
    moveToList: t("kf.moveToList.header"),
    moveToListDec: t("kf.moveToList.dec"),
    moveToListSuccessDec:t("kf.moveToList.success.dec")
  }
}
export const PRODUCT_DETAILS = "product details"
export const WISHLIST_SAVE = "wishlist save"
export const SELECT_MY_LIST = "select my list"
export const CLOSE = "close"
export const SAVE_TO_LIST = "save to list"
