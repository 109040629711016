import {
  PRODUCT_DETAILS,
  WISHLIST_SAVE,
  SELECT_MY_LIST,
  CLOSE,
  SAVE_TO_LIST,
} from "@/components/SaveToList/localization"
const pushToAdobeDataLayer = (eventInfo, productInfo) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = getState?.("page") || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    ...(productInfo ? { productInfo } : {}),
    page,
  })
}

export const dataLayerHeartClickSaveListButton = (
  productInfo,
  pageType = "",
  headerTitle
) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${PRODUCT_DETAILS}:${headerTitle?.toLowerCase()}`,
    eventMsg: "n/a",
    eventName: `${pageType}:${PRODUCT_DETAILS}:${headerTitle?.toLowerCase()}`,
    eventStatus: "n/a",
    eventType: `${WISHLIST_SAVE}`,
    internalLinkName: `${headerTitle?.toLowerCase()}`,
    internalLinkPosition: `${pageType}`,
    internalLinkType: `${pageType}:${headerTitle?.toLowerCase()}`,
    internalLinkURL: `n/a`,
    internalLinkZoneName: `${pageType}:${PRODUCT_DETAILS}:${headerTitle?.toLowerCase()}`,
  }
  pushToAdobeDataLayer(eventInfo, productInfo)
}

export const dataLayerCreateNewListButton = (
  productInfo,
  pageType = "",
  createNewListTitle
) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${PRODUCT_DETAILS}:${createNewListTitle?.toLowerCase()}`,
    eventMsg: "n/a",
    eventName: `${pageType}:${PRODUCT_DETAILS}:${createNewListTitle?.toLowerCase()}`,
    eventStatus: "n/a",
    eventType: `${WISHLIST_SAVE}`,
    internalLinkName: `${createNewListTitle?.toLowerCase()}`,
    internalLinkPosition: `${pageType}`,
    internalLinkType: `${pageType}:${SAVE_TO_LIST}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageType}:${PRODUCT_DETAILS}:${SAVE_TO_LIST}`,
  }
  pushToAdobeDataLayer(eventInfo, productInfo)
}

export const dataLayerMyListSaveListButton = (
  productInfo,
  pageType = "",
  selectedData = "",
  successDesc = "",
  successTitle
) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${PRODUCT_DETAILS}:${SELECT_MY_LIST}:${successTitle?.toLowerCase()}`,
    eventMsg: `${successDesc}`,
    eventName: `${pageType}:${PRODUCT_DETAILS}:${SELECT_MY_LIST}:${successTitle?.toLowerCase()}`,
    eventStatus: `${successTitle?.toLowerCase()}`,
    eventType: `${WISHLIST_SAVE}`,
    internalLinkName: `${selectedData}`,
    internalLinkPosition: `${pageType}`,
    internalLinkType: `${pageType}:${SAVE_TO_LIST}:${selectedData}`,
    internalLinkURL: `n/a`,
    internalLinkZoneName: `${pageType}:${PRODUCT_DETAILS}:${SAVE_TO_LIST}`,
  }
  pushToAdobeDataLayer(eventInfo, productInfo)
}

export const dataLayerCreateNewSaveListButton = (
  productInfo,
  pageType = "",
  createListData = "",
  successDesc = "",
  createNewListTitle,
  successTitle
) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${PRODUCT_DETAILS}:${createNewListTitle?.toLowerCase()}:${successTitle?.toLowerCase()}`,
    eventMsg: `${successDesc}`,
    eventName: `${pageType}:${PRODUCT_DETAILS}:${createNewListTitle?.toLowerCase()}:${successTitle?.toLowerCase()}`,
    eventStatus: `${successTitle?.toLowerCase()}`,
    eventType: `${WISHLIST_SAVE}`,
    internalLinkName: `${createListData}`,
    internalLinkPosition: `${pageType}`,
    internalLinkType: `${pageType}:${SAVE_TO_LIST}:${createListData}`,
    internalLinkURL: `n/a`,
    internalLinkZoneName: `${pageType}:${PRODUCT_DETAILS}:${SAVE_TO_LIST}`,
  }
  pushToAdobeDataLayer(eventInfo, productInfo)
}

export const closeSaveToListAnalytics = (productInfo, pageType = "") => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${PRODUCT_DETAILS}:${SAVE_TO_LIST}:${CLOSE}`,
    eventMsg: "n/a",
    eventName: `${pageType}:${PRODUCT_DETAILS}:${SAVE_TO_LIST}:${CLOSE}`,
    eventStatus: "n/a",
    eventType: `${WISHLIST_SAVE}`,
    internalLinkName: `${CLOSE}`,
    internalLinkPosition: `${pageType}`,
    internalLinkType: `${pageType}:${SAVE_TO_LIST}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageType}:${PRODUCT_DETAILS}:${SAVE_TO_LIST}`,
  }
  pushToAdobeDataLayer(eventInfo, productInfo)
}

export const closeCreateNewListAnalytics = (
  productInfo,
  pageType = "",
  createNewListTitle
) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${PRODUCT_DETAILS}:${createNewListTitle?.toLowerCase()}:${CLOSE}`,
    eventMsg: "n/a",
    eventName: `${pageType}:${PRODUCT_DETAILS}:${createNewListTitle?.toLowerCase()}:${CLOSE}`,
    eventStatus: "n/a",
    eventType: `${WISHLIST_SAVE}`,
    internalLinkName: `${CLOSE}`,
    internalLinkPosition: `${pageType}`,
    internalLinkType: `${pageType}:${SAVE_TO_LIST}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageType}:${PRODUCT_DETAILS}:${SAVE_TO_LIST}`,
  }
  pushToAdobeDataLayer(eventInfo, productInfo)
}

export const closeCreateNewListSuccessAnalytics = (
  productInfo,
  pageType = "",
  createListData = "",
  createNewListTitle,
  successTitle
) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${PRODUCT_DETAILS}:${createNewListTitle?.toLowerCase()}:${successTitle?.toLowerCase()}:${CLOSE}`,
    eventMsg: "n/a",
    eventName: `${pageType}:${PRODUCT_DETAILS}:${createNewListTitle?.toLowerCase()}:${successTitle?.toLowerCase()}:${CLOSE}`,
    eventStatus: "n/a",
    eventType: `${WISHLIST_SAVE}`,
    internalLinkName: `${CLOSE}`,
    internalLinkPosition: `${pageType}`,
    internalLinkType: `${pageType}:${SAVE_TO_LIST}:${createListData}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageType}:${PRODUCT_DETAILS}:${SAVE_TO_LIST}`,
  }
  pushToAdobeDataLayer(eventInfo, productInfo)
}

export const closeSelectMyListAnalytics = (
  productInfo,
  pageType = "",
  selectedData = ""
) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${PRODUCT_DETAILS}:${SELECT_MY_LIST}:${CLOSE}`,
    eventMsg: "n/a",
    eventName: `${pageType}:${PRODUCT_DETAILS}:${SELECT_MY_LIST}:${CLOSE}`,
    eventStatus: "n/a",
    eventType: `${WISHLIST_SAVE}`,
    internalLinkName: `${CLOSE}`,
    internalLinkPosition: `${pageType}`,
    internalLinkType: `${pageType}:${SAVE_TO_LIST}:${selectedData}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageType}:${PRODUCT_DETAILS}:${SAVE_TO_LIST}`,
  }
  pushToAdobeDataLayer(eventInfo, productInfo)
}

export const closeSelectMyListSuccessAnalytics = (
  productInfo,
  pageType = "",
  selectedData = "",
  successTitle
) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${PRODUCT_DETAILS}:${SELECT_MY_LIST}:${successTitle}:${CLOSE}`,
    eventMsg: "n/a",
    eventName: `${pageType}:${PRODUCT_DETAILS}:${SELECT_MY_LIST}:${successTitle}:${CLOSE}`,
    eventStatus: "n/a",
    eventType: `${WISHLIST_SAVE}`,
    internalLinkName: `${CLOSE}`,
    internalLinkPosition: `${pageType}`,
    internalLinkType: `${pageType}:${SAVE_TO_LIST}:${selectedData}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageType}:${PRODUCT_DETAILS}:${SAVE_TO_LIST}`,
  }
  pushToAdobeDataLayer(eventInfo, productInfo)
}
