export const getTranslations = t => {
  return {
    listActionSuccess: t("kf.saveToList.action.success.title"),
    listActionDeleteMsg: t("Kf.myList.page.delete.confirm.msg"),
    listActionDeleteSuccessBtn: t("kf.account.label.myLists"),
    noListTitle: t("Kf.myList.page.nolist"),
    noListMsg: t("Kf.myList.page.nolist.msg"),
    bestSellersBtn: t("Kf.myList.page.shop.bestsellers"),
    deleteListTitle: t("kf.myList.page.deletList"),
    deleteDec: t("Kf.myList.page.delete.dec"),
    deleteListBtn: t("kf.myList.page.deletList"),
    deleteFromList: t("kf.myList.page.deletFromList"),
    deleteFromListDec: t("kf.myList.page.deletFromList.msg"),
    deleteFromListConfirmMsg: t("kf.myList.page.deletFromList.confirm.msg"),
    deleteProductFromList: t("kf.button.action.delete"),
    closeBtnTextForList: t("kf.checkout.label.close"),
    deleteProductFormListCloseMsg: t("kf.myList.page.deleteProduct.dec"),
    multiDeleteHeader: t("kf.delete.items.from.list.header"),
    multiDeleteFromListDec: t("kf.delete.items.from.list.dec"),
    multiDeleteBtnTex: t("kf.delete.items.from.list.btn"),
    multiDeleteSuccessDec: t("kf.delete.items.from.list.success.dec"),
    multiDeleteSuccessBtnTex: t("kf.myList.page.delete.success.btn"),
  }
}
